html, 
body {
    max-height: 100%;
    max-width: 100%;
    margin: 0px;
}

video {
    z-index: 1;
    height: 20%;
    width: 20%;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0px;
}

h1 {
    text-align: center;
    font-family: 'Fredoka One', cursive;
    font-size: 50pt;
}

.explanation {
   background-color: #7D82B8;
   height: 100%;
   width: 100%;
   color: white;
   z-index: 1;
   text-align: justify;
   -moz-text-size-adjust: 80%;
   font-family: 'Raleway', sans-serif;
   font-size: 13pt;
   position: absolute;
   top: 0%;
}

.text {
    padding-top: 0%;
    padding-left: 25%;
    padding-right: 25%
}

.button {
    position: center;
    background-color: #C4F4C7;
    padding: 2%;
    width: 120pt;
    height: 5%;
    color: black;
    text-align: center;
    border-radius: 15px;
    margin-left: 42%;
    margin-top: 10%;
}

.button:hover {
    background-color: #F7A9A8;
    transition: 0.3s;
    cursor: pointer;
    color: white;
}

.button:active {
    background-color: #EE4744;
}

.controls{
    z-index: 2;
    position: absolute;
    right:0;
    margin: 0.5%;
    text-align-last: center;
    align-items: center;
    display: flex;
}

.icon {
    z-index: 2;
    position: absolute;
    margin: 0.5%;
    height: 34px;
}

.logo {
    margin-left: 44px;
}

.volume {
    height: 34px;
}

.talk {
    float: right;
    margin: 1rem;
    height: 34px;
    cursor: pointer;
}

.hide{
    display: none !important;
}